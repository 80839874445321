/* PC Component Library
 * Author: Preetha Ravichandran
 * Updated On: 11-07-2022
 */

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import '@scuf/common/honeywell-compact-combined/theme.css';
import '@scuf/datatable/honeywell-compact-combined/theme.css';
import store from './stores';
import { Provider } from 'mobx-react';
import createRouter from './Router';
import './index.scss';


// Here we are importing our stores file and spreading it across this Provider. All stores added to this will be accessible via child injects
const wrappedApp = (
  <Provider {...store}>
      <App />
  </Provider>
);

// Here the router is bootstrapped
const router = createRouter();

router.start(() => {
  ReactDOM.render(wrappedApp, document.getElementById('root') as HTMLElement);
});