/*
 * PC Container POC
 * Author: Thasneem Nizar
 * Updated On: 23-11-2022
 *
 *
 */

/*
* Enum for
* theme change
*
*
*
*/
/*export statement*/
/*theme change*/
export enum Theme {
    LIGHT = 'Light',
    DARK = 'Dark'}
