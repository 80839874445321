/* PC Component Library
 * Author: Preetha Ravichandran
 * Updated On: 11-07-2022
 */

export default class FeatureState {
    public isDeleteEnabled = true;

    public setIsDeleteEnabled(isDeleteEnabled:boolean) {
        this.isDeleteEnabled = isDeleteEnabled;
    }
};