/* PC Component Library
 * Author: Preetha Ravichandran
 * Updated On: 11-07-2022
 *
 */
export interface IMappedTemplate {
    id?:string;
    discription?:string;
    name:string;
    serviceId?:string;
    templateId?:string;
}

export interface ITemplateListObject {
    id:string;
    isDefaultTemplate:string;
    name?:string;
    siteId?:string;
    isEnabled:boolean;
    status:string;
}

export interface IIconsPosition {
    top:boolean;
    left:boolean;
}

export interface IEnergyCarbonData {
    consumption: number;
    baselineConsumption: number;
    consumptionDate: string;
}

export interface IEnergyCarbonGraphData {
    siteId: string;
    energyDataConsumption: IEnergyCarbonData[];
    carbonDataConsumption: IEnergyCarbonData[];
    overallReductionPercentage: number;
    carbonBaseline:number;
    carbonConsumption: number;
    pastCRDisplayPercentage: number;
    energyBaseline:number;
    carbonReduction: number;
    energySavings: number;
    pastESDisplayPercentage: number;
    energyConsumption: number;
    absorptionCount: number;
    gallonsOfGas: number;
    date: string;
    metricType: string;
    carbonReductionIncreased: boolean;
    energySavingsIncrased: boolean;
}

export interface IDeviceData{
    deviceId:string;
    deviceName:string;
}
export interface IGenerateDeviceIdResponse{
    isSuccess:boolean;
    message:string;
    data:IDeviceData;
    code:string;
}
export const IDisclaimerText = {
    Slide1: '* Estimate based on Scope 2 energy consumption associated with this building over the last year. Actual results may differ. These estimates are approximate and not a guarantee of performance.',
    Slide2: '* Estimate based on Scope 2 energy consumption associated with this building over the last 5 days. Actual results may differ. These estimates are approximate and not a guarantee of performance.',
    Slide3: `* Calculated using the EPA’s Greenhouse Gas Equivalencies Calculator (July 2023) and the Scope 2 energy consumption associated with this building over the last 5 days. Actual results may differ. These estimates are approximate and not a guarantee of performance.`
  };