/* PC Component Library
 * Author: Preetha Ravichandran
 * Updated On: 11-07-2022
 */

import { GlobalRouterStore } from './stores/RouterStore';
import GlobalStore from './stores/GlobalStore/GlobalStore';
import TodoStore from './stores/TodoStore/TodoStore';
/* 
    - This is where the stores are instantiated and given their dependencies.
    - The keys here are referenced in each component's inject statements.
    - Remember that every new instance of a store class will have unique states, so it is best to instantiate them once at this level.
*/

export default {
    routerStore: new GlobalRouterStore(),
    todoStore: new TodoStore(),
    globalStore: new GlobalStore()};