/* PC Component Library
 * Author: Preetha Ravichandran
 * Updated On: 11-07-2022
 */

import { createRouter } from 'router5';
import { mobxPlugin } from 'mobx-router5';
import loggerPlugin from 'router5-plugin-logger';
import browserPlugin from 'router5-plugin-browser';

import routes from './routes';
import stores  from './stores';
// See router5 for more details about this file
export default function router() {
    return createRouter(routes, { allowNotFound: true })
        .usePlugin(mobxPlugin(stores.routerStore))
        .usePlugin(browserPlugin({ useHash: false }))
        // Important step so we can expose store
        .usePlugin(loggerPlugin); // Used for logging out state
}
