/* PC Component Library
 * Author: Preetha Ravichandran
 * Updated On: 11-07-2022
 *
 */
import React, { Component, Fragment, Suspense } from "react";
import { observer } from "mobx-react";
import { RouterStore } from "mobx-router5";
import LaunchDarkly, { LDUser, LDClient } from "launchdarkly-node-server-sdk";
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import FeatureState from "./stores/FeatureState";
import { AnalyticsComponent } from './utils/Analytics/UseAnalytics';
import TodoStore from './stores/TodoStore/TodoStore';
import { GlobalRouterStore } from './stores/RouterStore';
import { BrowserRouter,Routes, Route } from 'react-router-dom';
import {
  launchDarklyUser,
  launchDarklyFeatureToggleKey
} from "./launchDarklyConfig";
import GlobalStore from "./stores/GlobalStore/GlobalStore";
import SignageViewContainer from './pages/SignageView/SignageViewContainer';
import {NotFoundPage} from "./pages/NotFound/NotFound";

export interface IProps {
  routerStore: RouterStore;
  globalStore: GlobalStore;
}
declare var fetchEnvVars: any;
@observer
export class App extends Component<{routerStore?: GlobalRouterStore, globalStore?: GlobalStore, todoStore?: TodoStore}, FeatureState> {
  public featureState: FeatureState = new FeatureState();
  constructor(props:{routerStore?: GlobalRouterStore, globalStore?: GlobalStore, todoStore?: TodoStore}) {
    super(props);
    let user = launchDarklyUser as LDUser;
    if (typeof fetchEnvVars !== "undefined") {
      fetchEnvVars
        .then((data: any) => {
          let client = LaunchDarkly.init(
            data[window.ENV.LAUNCHDARKLYCLIENTID] as string,
            user           
          );
          client.on("ready", this.getFeatureFlags.bind(this, client));
          client.on("change", this.getFeatureFlags.bind(this,client));
        })
        .catch(function notOk(err: any) {
          console.error(err);
        });
    }
  }

  async getFeatureFlags(client: LDClient) {
    let featureState = await client.variation(launchDarklyFeatureToggleKey.DELETE_FEATURE_KEY,launchDarklyUser, false);
    this.featureState.setIsDeleteEnabled(
      featureState
    );
    this.setState(this.featureState);
  }

  render() {
    return (
      <Fragment>
        <Suspense fallback={<NotFoundPage/>}>
          <BrowserRouter>
          <Routes>
            <Route path='/not-found' element={ <NotFoundPage/>} />

            <Route path='/:customerName/:activationCode' element={ <SignageViewContainer/>} />
            <Route path='/' element={ <SignageViewContainer/>} />
          </Routes>
        </BrowserRouter>
        </Suspense>
        <AnalyticsComponent todoStore = {this.props.todoStore} globalStore = {this.props.globalStore}  />
      </Fragment>
    );
  }
}

export default withLDProvider({clientSideID: window.ENV.LD_CLIENT_ID})(App);
