/*
 * Author: Manikandabose Nagarajan
 * Created on:24-11-2022
 */

import { analytics } from '@hcecbp/webappanalytics';
import { autorun } from 'mobx';
import React, { useEffect, useState } from 'react';
import TodoStore from '../../stores/TodoStore/TodoStore';
import GlobalStore from "../../stores/GlobalStore/GlobalStore";
const defaultTagEventDelay = 2000;

export function AnalyticsComponent(props: {
  tagEventDelay?: number;
  todoStore?:TodoStore;
  globalStore?: GlobalStore;
}) {
  useAnalytics(props);
  return <div />;
}

export function useAnalytics({
  tagEventDelay,
  todoStore,
  globalStore
}: {
  tagEventDelay?: number;
  todoStore?:TodoStore;
  globalStore?:GlobalStore;
}) {
  const [organization, setOrganization]: [string | undefined, (_?: string) => void] = useState();
  const [scope, setScope]: [string | undefined, (_?: string) => void] = useState();
  const [feature, setFeature]: [string | undefined, (_?: string) => void] = useState();

  useEffect(
    () =>
      autorun(() => {
        if (!todoStore) {
          return;
        }
        if(!globalStore)
        {
          return;
        }
        if(globalStore?.LOCALYTICS_APP_KEY === "")
        {
          globalStore.fetchLocalyticsAppKey();
          analytics.initialise({
            appKey: globalStore.LOCALYTICS_APP_KEY
        });
        }
        const routeName = todoStore?.modalType || 'landing';
        const newFeature = routeName ;
        setFeature(newFeature); //description 
        setOrganization("Honeywell"); //customer
        setScope("All Sites"); //application - some sites
      }),
    [ todoStore] //impplementation
  );

  useEffect(() => {
    if (!!organization) {
      analytics.setOrganization(organization);
    }
    if (!!scope) {
      analytics.setScope(scope);
    }
  }, [organization, scope]);

  useEffect(() => {
    if (!!feature && feature !== 'unknown') {
      analytics.tagScreen(feature);
    }
  }, [feature]);

  useEffect(() => {
    const i = setTimeout(
      () =>
        (feature ) &&
        analytics.tagEvent('navigate', {
          // IMPORTANT! even though this tagEvent does not use org or
          // scope we want the navigate event any time they change.
          Feature: feature}),
      tagEventDelay || defaultTagEventDelay
    );
    return () => clearTimeout(i);
  }, [organization, scope, feature,  tagEventDelay]);
}
