/* PC Component Library
 * Author: Preetha Ravichandran
 * Updated On: 11-07-2022
 */

import NotFoundPage from './pages/NotFound/NotFound';
import { constants } from 'router5';
import SignageViewContainer from './pages/SignageView/SignageViewContainer';
/* 
    - Routes.ts is the master router config file. Each item defines a route by its name and the parameters it accepts.
    - The component bound to the route will be loaded into the base route view component.
*/
export default [
    {
        name: 'default',
        path: '/:activationCode',
        component: SignageViewContainer
    },
    // This entry captures any unknown routes and redirects them to a component. This is our 404 page.
    {
        name: constants.UNKNOWN_ROUTE,
        path: '/not-found',
        component: NotFoundPage
    }
];