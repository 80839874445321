/* PC Component Library
 * Author: Preetha Ravichandran
 * Updated On: 11-07-2022
 */

import React from 'react';
import { Icon, Button } from '@scuf/common';
import { observer, inject } from 'mobx-react';
import './NotFound.scss';

export const NotFoundPage = observer((props:any) => {
  const router = props.routerStore!;

  return (
    <div className="not-found-container">
      <div className="not-found">
          <Icon name="badge-warning" size="xlarge"/>
          <h1 id="headerPageNotFound">Page Not Found</h1>
          <Button className="routeToHomeButton" onClick={() => router.navigate('todo')} type="secondary" content="Please return to landing page."/>
      </div>
    </div>
    );
});

export default inject('routerStore')(NotFoundPage);