/* PC Component Library
 * Author: Preetha Ravichandran
 * Updated On: 11-07-2022
 */

import { observable, action } from 'mobx';

declare global {
    interface Window {
      ENV: {
        DS_DATA_FOR_NO_OF_DAYS: string;
        BASE_URL_API_GATEWAY: string;
        CLIENT_ID: string;
        AUTHORITY: string;
        LOCALYTICS_SESSION_TIMEOUT: number;
        LAUNCHDARKLYCLIENTID:string;
        LD_CLIENT_ID: string;
        GRAPH_DATA_REFRESH_TIMER: number,
        TEMPLATE_DATA_REFRESH_TIMER: number,
        SLIDER_TIMER: number
      };
    }
  }

export default class GlobalStore {

    @observable
    public sideBarCollapsed: boolean = false;
    @observable
    public LOCALYTICS_APP_KEY: string = "";

    @action
    toggleSideBar(state: boolean) {
        this.sideBarCollapsed = state;
    }

    @action
    setLocalyticsAppKey(state: string)
    {
      this.LOCALYTICS_APP_KEY = state;
    }
    @action
    fetchLocalyticsAppKey() {
      //make fetch call to get the Localytics APP Key 
      //response = api_call
      //this.setLocalyticsAppKey(response);
      this.setLocalyticsAppKey("App-key-Value-from-API");
    }
} 