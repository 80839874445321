/*
 * Author: Manikandabose Nagarajan
 * Created on:24-11-2022
 */

import { observable, computed, action } from 'mobx';
import { MockTodos } from './MockData';
import ITodoModel from '../../models/ITodoModel';

export default class TodoStore {
    @observable addDialogOpened = false;
    @observable editDialogOpened = false;
    @observable activeTodo: Partial<ITodoModel> | undefined;

    @observable todos: Array<ITodoModel> = [];

    @computed get completedTodos() {
        return this.todos.filter(todo => todo.completed);
    }

    @computed get activeTodos() {
        return this.todos.filter(todo => !todo.completed);
    }

    @computed get isModalOpen() {
        return this.addDialogOpened || this.editDialogOpened;
    }

    @computed get modalType() {
        if (this.addDialogOpened && !this.editDialogOpened) {
            return 'add';
        }
        if (!this.addDialogOpened && this.editDialogOpened) {
            return 'edit';
        }
        return 'unknown';
    }

    @action 
    public loadTodos() {
        this.todos = MockTodos;
    }

    @action
    public showModal(mode: 'add' | 'edit') {
        if (mode === 'add') {
            this.addDialogOpened = true;
            this.editDialogOpened = false;
        }

        if (mode === 'edit') {
            this.addDialogOpened = false;
            this.editDialogOpened = true;
        }
    }

    @action
    public addTodo(todo: ITodoModel) {
        const newTodo: ITodoModel = {
            id: this.generateId(), 
            title: todo.title,
            description: todo.description,
            dateCreated: new Date(),
            completed: false
        };
        this.todos.push(newTodo);
    }

    @action
    public editTodo(todo: ITodoModel) {
        const findTodo = this.todos.find(item => item.id === todo.id!);
        if (findTodo) {
            findTodo.title = todo.title;
            findTodo.description = todo.description;
        }
    }

    @action
    public destroyTodo(todo: ITodoModel) {
        this.todos.splice(this.todos.indexOf(todo), 1);
    }

    @action
    public toggleTodoComplete(todo: ITodoModel) {
        const findTodo = this.todos.find(item => item.id === todo.id);
        if (findTodo) {
            findTodo.completed = !findTodo.completed;
        }
    }

    @action
    public editActiveTodo(todo: ITodoModel) {
        this.showModal('edit');
        this.activeTodo = todo;
    }

    @action
    public dismissModal() {
        this.editDialogOpened = false;
        this.addDialogOpened = false;
    }

    private generateId() {
        return Math.random().toString();
    }

}